body {
  &.catalog-category-view,
  &.catalog-product-view {
    .page-wrapper {
      .page-footer {
        @include content(full);
        background: url($imageDir + "footer-background.jpg") no-repeat;
        background-size: cover;
      }
    }
  }
}

