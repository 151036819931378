body {
  &.checkout-onepage-success,
  &.checkout-index-index {
    .page-wrapper {
      .page-main {
        padding: 0 2.5rem;

        .columns {
          @include content(medium);
          margin-top: 10rem;
          margin-bottom: 10rem;

          .column {
            &.main {
              .checkout-container {
                display:flex;
                flex-wrap:wrap;
                justify-content: space-between;

                > .messages {
                  order:1;
                }

                .opc-progress-bar {
                  order:2;
                  margin-bottom: 5rem;
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  gap: 2rem;
                  counter-reset: i;

                  li {
                    margin: 0;
                    padding: 0.7rem 3rem;
                    position: relative;
                    text-align: center;
                    background-color: $colorGrey;

                    > span {
                      font-weight: 500;
                      font-size: 1.4rem;
                      line-height: 3rem;
                      letter-spacing: 4px;
                      text-transform: uppercase;
                      display: block;
                      color:$colorPrimary;

                      &:before {
                        color:$colorPrimary;
                        font-weight: 500;
                        content: counter(i);
                        counter-increment: i;
                        display: inline-block;
                        margin-right: 1rem;
                      }
                    }

                    &._active {
                      background-color: $colorSecondary;

                      > span {
                        color:$colorWhite;

                        &:before {
                          color:$colorWhite;
                        }
                      }
                    }

                    &._complete {
                      background-color: $colorPrimary;
                      cursor: pointer;

                      > span {
                        color:$colorWhite;

                        &:before {
                          color:$colorWhite;
                        }
                      }
                    }
                  }
                }

                .opc-estimated-wrapper {
                  display:none;
                }

                .shipping-address-items {
                  display:flex;
                  flex-wrap:wrap;
                  gap:2.5rem;

                  .shipping-address-item {
                    width: calc((100% - 5rem) / 3);
                    background-color: $colorWhite;
                    box-shadow: 1px 1px 5px 1px rgba(0,0,0,.15);
                    padding: 2rem;

                    .action {
                      width:100%;
                      margin-top:2rem;
                    }
                  }
                }

                .new-address-popup {
                  margin:2.5rem 0 5rem 0;
                }

                .opc-wrapper {
                  width: calc(100% - 37rem);
                  order:3;

                  .step-title {
                    font-weight: 400;
                    font-size: 4.5rem;
                    line-height: 6rem;
                    letter-spacing: 1px;
                    display: block;
                    margin-bottom: 2rem;
                    border:0;
                    width: 100%;
                  }

                  fieldset {
                    legend {
                      display:none;
                    }
                  }

                  .form-login,
                  #opc-shipping_method {
                    position:relative;
                  }

                  #shipping-new-address-form,
                  [data-form="billing-new-address"] {
                    display: flex;
                    flex-wrap: wrap;
                    -webkit-box-pack: justify;
                    justify-content: space-between;

                    > .field {
                      width: 49%;
                      margin-bottom: 1.5rem;
                      padding:0;

                      .field {
                        margin-bottom:0;
                      }

                      &.field-select-billing {
                        display:none;
                      }

                      &.street {
                        legend {
                          display: none;
                        }
                      }
                    }
                  }

                  .checkout-billing-address {
                    font-size: $fontSizeDefaultSmall;
                    line-height: $lineHeightDefaultSmall;

                    .billing-address-same-as-shipping-block {
                      margin-bottom: 2rem;
                      cursor: pointer;
                    }

                    .billing-address-details {
                      .action {
                        margin-top:2rem;
                      }
                    }

                    .field-select-billing {
                      > label {
                        display:none;
                      }
                    }

                    .billing-address-form {
                      .fieldset.address {
                        padding:0;

                        .field {
                          &.choice {
                            margin-bottom:0;
                          }
                        }
                      }
                    }

                    .actions-toolbar {
                      .primary {
                        display: flex;
                        gap: 1rem;
                      }
                    }
                  }

                  #checkout-payment-method-load {
                    margin-top:3.5rem;
                  }

                  #checkout-shipping-method-load,
                  #checkout-payment-method-load .payment-methods-list {
                    background: $colorWhite;
                    padding: 1rem 2.5rem;
                    margin-bottom:1rem;
                    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);

                    .row {
                      display: flex;
                      flex-wrap: nowrap;
                      cursor:pointer;
                      padding:1rem 0;
                      margin-bottom:1rem;

                      &:last-child {
                        margin-bottom:0;
                      }

                      .col {
                        padding: 0 2rem 0 0;
                        font-size: $fontSizeInput;
                        line-height: 3rem;
                        font-weight: 500;
                        letter-spacing: 4px;
                        text-transform: uppercase;

                        &:first-child {
                          display: flex;
                          align-items: center;
                        }
                      }
                    }

                    .payment-method {
                      .payment-method-title {
                        display: flex;
                        flex-wrap: nowrap;
                        padding: 1rem 0;
                        margin: 0;
                        cursor:pointer;

                        > input {
                          margin-right: 2rem;
                          cursor:pointer;
                        }

                        > label {
                          cursor:pointer;
                        }
                      }

                      &._active {
                        .payment-method-content {
                          display:block;
                        }
                      }

                      .payment-method-content {
                        display:none;
                        padding: 0 0 2rem 0;
                        font-size: $fontSizeDefaultSmall;
                        line-height: $lineHeightDefaultSmall;

                        .checkout-agreements-block {
                          padding: 2rem 0 0 0;

                          .checkout-agreements {
                            .checkout-agreement {
                              display: flex;
                              flex-wrap: wrap;

                              &.field {
                                > input {
                                  margin-right:1rem;
                                  cursor: pointer;
                                }
                              }
                            }
                          }

                          .order-comment-wrapper {
                            margin-top: 4rem;
                          }
                        }
                      }
                    }
                  }

                  .actions-toolbar {
                    margin-top:3rem;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1rem;
                  }
                }

                #opc-sidebar {
                  width: 33rem;
                  background: $colorGrey;
                  color: $colorPrimary;
                  padding: 2.5rem;
                  position:relative;
                  order:4;

                  .opc-block-summary {
                    > .title {
                      font-weight: 400;
                      font-size: 2.5rem;
                      line-height: 3rem;
                      letter-spacing: 1px;
                      display: block;
                      padding-bottom: 0.5rem;
                      border-bottom: 1px solid #000;
                      margin-bottom: 2rem;
                    }

                    .table-totals {
                      margin-bottom: 2rem;
                      border-bottom: 1px solid #000;
                      padding-bottom: 1.5rem;

                      tr {
                        font-size: 1.6rem;
                        line-height: 2.6rem;

                        th {
                          font-weight: 400;
                        }

                        td {
                          text-align:right;
                        }
                      }
                    }

                    .block {
                      &.items-in-cart {
                        border-bottom: 1px solid #000;
                        padding-bottom: 0;
                        margin-bottom: 2rem;

                        .title {
                          display:none;
                        }

                        .actions-toolbar {
                          font-size: $fontSizeSmall;
                          line-height: $lineHeightSmall;

                          a {
                            text-decoration:underline;
                          }
                        }

                        .content {
                          ol {
                            li {
                              margin-bottom:2rem;
                              max-width:none;

                              .product {
                                display:flex;
                                justify-content: space-between;

                                .product-item-details {
                                  width: calc(100% - 9rem);
                                  margin-top:0;

                                  .product-item-inner {
                                    .product-item-name {
                                      font-weight:400;
                                      font-size:$fontSizeDefaultSmall;
                                      line-height:$lineHeightDefaultSmall;
                                      margin-bottom: 0;
                                      display: block;
                                    }

                                    .subtotal,
                                    .details-qty {
                                      font-size:$fontSizeDefaultSmall;
                                      line-height:$lineHeightDefaultSmall;
                                    }
                                  }

                                  .product.options {
                                    display:none !important;
                                  }
                                }
                              }
                            }

                          }
                        }
                      }
                    }
                  }

                  .opc-block-shipping-information {
                    .shipping-information {
                      .shipping-information-title {
                        font-size: $fontSizeInput;
                        line-height: $lineHeightDefault;
                        font-weight: 500;
                        letter-spacing: 4px;
                        text-transform: uppercase;

                        button {
                          display:none;
                        }
                      }

                      .shipping-information-content {
                        font-weight:400;
                        //font-size:$fontSizeDefaultSmall;
                        //line-height:$lineHeightDefaultSmall;
                        margin-bottom:2rem;
                      }
                    }
                  }
                }
              }

              .checkout-success {
                margin-top: 4rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  body {
    &.checkout-onepage-success,
    &.checkout-index-index {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {
                .checkout-container {
                  .shipping-address-items {
                    .shipping-address-item {
                      width: calc((100% - 2.5rem) / 2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $smallDesktop) {
  body {
    &.checkout-onepage-success,
    &.checkout-index-index {
      .page-wrapper {
        .page-main {
          .columns {
            margin-top: 4.5rem;
            margin-bottom: 4.5rem;

            .column {
              &.main {
                .checkout-container {
                  .opc-wrapper {
                    width: calc(100% - 35rem);

                    #shipping-new-address-form,
                    [data-form="billing-new-address"] {
                      display: flex;
                      flex-wrap: wrap;
                      -webkit-box-pack: justify;
                      justify-content: space-between;

                      > .field {
                        width: 100%;
                      }
                    }
                  }

                  .shipping-address-items {
                    .shipping-address-item {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  body {
    &.checkout-onepage-success,
    &.checkout-index-index {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {
                .checkout-container {
                  .opc-wrapper {
                    width: 100%;
                    order:4;

                    .step-title {
                      font-size: 2.4rem;
                      line-height: 3rem;
                    }
                  }

                  #opc-sidebar {
                    width: 100%;
                    order:3;
                    margin-bottom:4rem;

                    .opc-block-summary {
                      > .title {
                        font-size: 2rem;
                        line-height: 2.4rem;
                      }

                      .table-totals {
                        tr {
                          font-size: 1.4rem;
                          line-height: 2rem;
                        }
                      }

                      .block {
                        &.items-in-cart {
                          .content {
                            ol {
                              li {
                                .product {
                                  .product-item-details {
                                    .product-item-inner {
                                      .product-item-name {
                                        font-size:1.4rem;
                                        line-height:2rem;
                                      }

                                      .subtotal,
                                      .details-qty {
                                        font-size:1.4rem;
                                        line-height:2rem;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  .opc-progress-bar {
                    li {
                      width: 100%;
                    }
                  }

                  .shipping-address-items {
                    .shipping-address-item {
                      width: calc((100% - 2.5rem) / 2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  body {
    &.checkout-onepage-success,
    &.checkout-index-index {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {
                .checkout-container {
                  .shipping-address-items {
                    .shipping-address-item {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}