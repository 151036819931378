body {
  &.catalog-category-view,
  &.catalogsearch-result-index,
  &.wishlist-index-index {
    .page-wrapper {
      .page-main {
        padding: 0 2.5rem;

        .columns {
          @include content(medium);
          margin-top: 10rem;
          margin-bottom: 10rem;

          .column {
            &.main {
              .category-view {
                @include content(box);
                margin-bottom: 6rem;
                text-align: center;

                .page-title-wrapper h1,
                .category-title {
                  font-weight: 500;
                  font-size: 1.4rem;
                  line-height: 3rem;
                  letter-spacing: 4px;
                  text-transform: uppercase;
                  margin-bottom: 4rem;
                }

                .category-description {
                  max-width: 85rem;
                  margin: 0 auto;
                }
              }

              .category-navigation {
                margin-bottom: 8rem;
                width: calc(100% + 6px);
                margin-left: -3px;
                display: flex;
                justify-content: center;

                .splide {
                  display:inline-block;
                  max-width: 100%;
                  visibility: visible !important;

                  .splide__arrows {
                    .splide__arrow {
                      background: $colorPrimary;
                      width: 4.5rem;
                      height: 4.5rem;
                      opacity: 1;
                      top: 50%;
                      transform: translateY(-50%);

                      &[disabled] {
                        display:none;
                      }

                      &--prev {
                        left: -2.25rem;

                        &:before {
                          font-family: "Font Awesome 6 Pro";
                          display: inline-block;
                          font-weight: 300;
                          content: "\f060";
                          color:$colorWhite;
                          font-size: 2rem;
                        }
                      }

                      &--next {
                        right: -1.8rem;

                        &:before {
                          font-family: "Font Awesome 6 Pro";
                          display: inline-block;
                          font-weight: 300;
                          content: "\f061";
                          color:$colorWhite;
                          font-size: 2rem;
                        }
                      }

                      svg {
                        display:none;
                      }
                    }
                  }

                  .splide__track {
                    padding: 3px !important;

                    .items {
                      .item {
                        min-width: 21.8rem;
                        max-width: 21.8rem;
                        margin-right: 2rem;
                        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
                        border-bottom:4px solid transparent;

                        &.current {
                          border-color:$colorSecondary;
                        }

                        a {
                          display: flex;
                          align-items: center;
                          flex-wrap: wrap;
                          height: 100%;
                          line-height: 2rem;
                          padding: 2rem;

                          .name,
                          .info {
                            font-size: 1.3rem;
                            font-weight: 400;
                            line-height: 2.4rem;
                            letter-spacing: 0.195px;
                            width: 100%;
                            display: inline-block;
                            text-align: center;
                          }
                        }
                      }
                    }
                  }
                }
              }

              .block {
                &.filter {
                  margin-bottom: 8rem;
                  position:relative;

                  .filter-title {
                    display: none;
                  }

                  .filter-content {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 6rem;

                    .filter-actions {
                      a {
                        display: block;
                        font-size: 1.4rem;
                        font-weight: 500;
                        line-height: 3rem;
                        letter-spacing: 4px;
                        text-transform: uppercase;
                        color:$colorPrimary;
                        text-align: center;
                        padding-bottom: 4px;
                        cursor:pointer;
                      }
                    }

                    .filter-options {
                      @include content(tiny);

                      .filter-wrapper-title {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        gap: 6rem;

                        .filter-options-title {
                          font-size: 1.4rem;
                          font-weight: 500;
                          line-height: 3rem;
                          letter-spacing: 4px;
                          text-transform: uppercase;
                          color:$colorPrimary;
                          text-align: center;
                          cursor:pointer;
                          border-bottom:2px solid;
                          border-color:transparent;

                          &:before {
                            font-family: "Font Awesome 6 Pro";
                            content: "\f078";
                            width: 2rem;
                            display: inline-block;
                          }

                          &:hover {
                            border-color:$colorPrimary;

                            &:before {
                              content: "\f054";
                            }
                          }
                        }
                      }

                      .filter-wrapper-content {
                        margin-top: 4rem;

                        .filter-options-content {
                          width: 100%;

                          &.active {
                            display: block !important;
                          }
                        }
                      }
                    }
                  }
                }
              }

              .products-grid {
                @include content(medium);
                padding: 0;

                .product-items {
                  display: grid;
                  grid-template-columns: repeat(auto-fill, minmax(27rem, 1fr));
                  grid-gap: 8rem;
                  justify-content: space-between;

                  .product-item {
                    max-width: 35.6rem;
                  }
                }
              }

              .toolbar-products {
                @include content(medium);
                margin-top: 15rem;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1700px) {
  body {
    &.catalog-category-view,
    &.catalogsearch-result-index {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {

                .category-navigation {
                  .splide {
                    .splide__arrows {
                      .splide__arrow {
                        &--prev {
                          left: 0;
                        }

                        &--next {
                          right: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  body {
    &.catalog-category-view,
    &.catalogsearch-result-index {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {
                .products-grid {
                  .product-items {
                    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
                    grid-gap: 4rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $smallDesktop) {
  body {
    &.catalog-category-view,
    &.catalogsearch-result-index {
      .page-wrapper {
        .page-main {
          .columns {
            margin-top: 4.5rem;
            margin-bottom: 4.5rem;

            .column {
              &.main {
                .category-view {
                  margin-bottom: 6rem;
                }

                .category-navigation {
                  .splide {
                    .splide__track {
                      .items {
                        .item {
                          min-width: 12.5rem;
                          max-width: 12.5rem;
                          margin-right: 1.5rem;

                          a {
                            padding: 1rem;
                          }
                        }
                      }
                    }
                  }
                }

                .block {
                  &.filter {
                    .filter-content {
                      gap: 2rem;

                      .filter-options {
                        gap: 2rem;
                      }
                    }
                  }
                }

                .products-grid {
                  .product-items {
                    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
                    grid-gap: 2rem;
                    justify-items: center;
                  }
                }

                .toolbar-products {
                  @include content(medium);
                  margin-top: 8rem;
                  padding: 0;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  body {
    &.catalog-category-view,
    &.catalogsearch-result-index {
      .page-wrapper {
        .page-main {
          padding: 0 1.5rem;

          .columns {
            .column {
              &.main {
                .category-view {
                  .page-title-wrapper h1,
                  .category-title {
                    font-size: 1.1rem;
                    line-height: 2.4rem;
                    margin-bottom:2rem;
                  }
                }
                .category-navigation {
                  margin-bottom: 4rem;

                  .splide {
                    .splide__arrows {
                      .splide__arrow {
                        width: 2rem;
                        height: 2rem;

                        &--prev {
                          left: 11.7rem;

                          &:before {
                            font-size: 1.2rem;
                          }
                        }

                        &--next {
                          right: auto;
                          left: 27.3rem;

                          &:before {
                            font-size: 1.2rem;
                          }
                        }
                      }
                    }
                  }
                }

                .block {
                  &.filter {
                    margin-bottom: 4rem;
                  }
                }

                .products-grid {
                  .product-items {
                    grid-template-columns: repeat(auto-fill, minmax(15.5rem, 1fr));
                    grid-gap: 6rem 2rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $phone) {
  body {
    &.catalog-category-view,
    &.catalogsearch-result-index {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {
                .block {
                  &.filter {
                    .filter-content {
                      .filter-actions {
                        width: 100%;

                        a {
                          width: 100%;
                        }
                      }

                      .filter-options {
                        width: 100%;

                        .filter-options-item {
                          width: 100%;

                          &.active {
                            &:after {
                              display: none;
                            }
                          }

                          .filter-options-title {
                            &:hover {
                              border-color:transparent;
                            }
                          }

                          .filter-options-content {
                            position:relative;
                            top: 0;

                            .items {
                              .item {
                                font-size: 1.2rem;
                                line-height: 1.8rem;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}