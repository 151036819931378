body {
  .page-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 10.4rem;

    .page-main {
      max-width: $maxWidth;
      width: 100%;
      margin: 0 auto;
      flex-grow: 1;
      overflow: clip;
      padding: 0 2.5rem;

      .columns {
        @include content(medium);
        margin-top: 10rem;
        margin-bottom: 10rem;

        .column {
          position:relative;

          &.header {
            width:100%;
          }

          &.main {
            width:100%;
          }

          &.side {
            width: 33rem;
            background: $colorGrey;
            color: $colorPrimary;
            padding: 2.5rem;
            position:relative;
          }
        }
      }
    }
  }

  &.page-layout-2columns-left {
    .page-wrapper {
      .page-main {
        .columns {
          display:flex;
          flex-wrap:wrap;
          justify-content: space-between;

          .column {
            &.main {
              order:2;
              width: calc(100% - 37rem);
            }

            &.side {
              order:1;
            }
          }
        }
      }
    }
  }

  &.page-layout-2columns-right {
    .page-wrapper {
      .page-main {
        .columns {
          display:flex;
          flex-wrap:wrap;
          justify-content: space-between;

          .column {
            &.main {
              order:1;
              width: calc(100% - 37rem);
            }

            &.side {
              order:2;
            }
          }
        }
      }
    }
  }

  &.cms-index-index {
    .page-wrapper {
      .page-main {
        padding: 0;

        .columns {
          @include content(max);
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  &.cms-page-view {
    .page-wrapper {
      .page-main {
        .columns {
          @include content(max);
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  body {
    .page-wrapper {
      padding-top: 9rem;
    }

    &.page-layout-2columns-right,
    &.page-layout-2columns-left {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {
                width:100%;
              }

              &.side {
                width:100%;
                margin-bottom:4rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $smallDesktop) {
  body {
    .page-wrapper {
      .page-main {
        .columns {
          margin-top: 4.5rem;
          margin-bottom: 4.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  body {
    .page-wrapper {
      padding-top: 8rem;
    }
  }
}