body {
  &.checkout-cart-index {
    .page-wrapper {
      .page-main {
        .columns {
          .column {
            &.main {

              .cart-empty {
                a {
                  text-decoration:underline;
                }
              }

              .cart-container {
                display:flex;
                flex-wrap:wrap;
                justify-content: space-between;

                .cart-form {
                  width: calc(100% - 37rem);

                  #gift-options-cart {
                    display: none;
                  }

                  .cart {
                    &.actions {
                      display:flex;
                      flex-wrap:wrap;
                      gap:1rem;
                    }

                    &.table-wrapper {
                      .table {
                        &.cart.items {
                          margin-bottom:2.5rem;

                          > thead {
                            display:none;
                          }

                          > tbody {
                            .item-info {
                              .col {
                                padding-right: 2rem;

                                &.image {
                                  padding-bottom: 2.5rem;
                                }

                                &.info {
                                  padding-bottom: 2.5rem;
                                }

                                &.item {
                                  padding-bottom: 2rem;
                                  padding-right: 0;

                                  .item-options {
                                    display:flex;
                                    flex-direction: column;

                                    dt {
                                      margin-right: 0.5rem;
                                    }

                                    dd {
                                      margin-right: 2rem;
                                    }
                                  }
                                }

                                &.qty {
                                  input {
                                    max-width: 20rem;
                                  }
                                }

                                &.action {
                                  text-align: right;

                                  .action {
                                    &-towishlist {
                                      &:before {
                                        font-size: 2rem;
                                        line-height: 3rem;
                                        font-weight: 300;
                                        font-family: "Font Awesome 6 Pro";
                                        content: "\f004";
                                        color: #000;
                                        display: inline-block;
                                      }

                                      span {
                                        display:none;
                                      }
                                    }

                                    &-delete {
                                      &:before {
                                        font-size: 2rem;
                                        line-height: 3rem;
                                        font-weight: 300;
                                        font-family: "Font Awesome 6 Pro";
                                        content: "\e2ae";
                                        color: #000;
                                        display: inline-block;
                                      }

                                      span {
                                        display:none;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }

                .cart-summary {
                  width: 33rem;
                  background: $colorGrey;
                  color: $colorPrimary;
                  padding: 2.5rem;
                  position:relative;

                  > .title {
                    font-weight: 400;
                    font-size: 2.5rem;
                    line-height: 3rem;
                    letter-spacing: 1px;
                    display: block;
                    padding-bottom: 0.5rem;
                    border-bottom: 1px solid $colorPrimary;
                    margin-bottom: 2rem;
                  }

                  .cart-totals {
                    .table-wrapper {
                      .table {
                        &.totals {
                          tr {
                            font-size: 1.6rem;
                            line-height: 2.6rem;

                            th {
                              font-weight: 400;
                            }

                            td {
                              text-align:right;
                            }
                          }
                        }
                      }
                    }
                  }

                  .block {
                    &.shipping {
                      display:none;
                    }

                    &.discount {
                      cursor: pointer;
                      margin: 2rem 0;
                      color: $colorPrimary;

                      .title {
                        display:none;
                      }

                      .content {
                        .fieldset.coupon {
                          .field {
                            label {
                              display: none;
                            }
                          }

                          .actions-toolbar {
                            button {
                              width:100%;
                            }
                          }
                        }
                      }
                    }
                  }

                  .checkout-methods-items {
                    .item {
                      button {
                        width: 100%;
                      }

                      .paypal {
                        input {
                          &[type="image"] {
                            max-width: 16rem;
                            background: transparent;
                            box-shadow: none;
                            margin: 2rem auto 0 auto;
                            display: block;
                            padding: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $smallDesktop) {
  body {
    &.checkout-cart-index {
      .page-wrapper {
        .page-main {
          .columns {
            margin-top: 4.5rem;
            margin-bottom: 4.5rem;

            .column {
              &.main {
                .cart-container {
                  .cart-form {
                    width: calc(100% - 35rem);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  body {
    &.checkout-cart-index {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {
                .cart-container {
                  gap: 4rem;

                  .cart-form {
                    width: 100%;

                    .cart {
                      &.actions {
                        a,
                        button {
                          width:100%;
                        }
                      }

                      &.table-wrapper {
                        .table {
                          &.cart.items {
                            margin-bottom: 1.5rem;

                            > tbody {
                              .item-info {
                                .col {
                                  padding-right: 1rem;

                                  &.image {
                                    img {
                                      min-width: 6rem;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  .cart-summary {
                    width: 100%;

                    > .title {
                      font-size: 2rem;
                      line-height: 2.4rem;
                    }

                    .cart-totals {
                      .table-wrapper {
                        .table {
                          &.totals {
                            tr {
                              font-size: 1.4rem;
                              line-height: 2rem;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


