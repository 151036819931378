.design-dark,
.design-transparent {
  .product-item {
    .product-item-details {
      .product-item-name {
        .product-item-link {
          color:$colorWhite;
        }
      }

      .product-item-price {
        .price-box {
          .old-price {
            .price {
              color:$colorWhite;
            }
          }
          .regular-price,
          .price-from,
          .price-to,
          .price-as-configured {
            .price {
              color:$colorWhite;
            }
          }
        }
      }
    }
  }
}

.design-light {
  .product-item {
    .product-item-details {
      .product-item-name {
        .product-item-link {
          color:$colorPrimary;
        }
      }

      .product-item-price {
        .price-box {
          .old-price {
            .price {
              color:$colorPrimary;
            }
          }

          .regular-price,
          .price-from,
          .price-to,
          .price-as-configured {
            .price {
              color:$colorPrimary;
            }
          }
        }
      }
    }
  }
}

.product-item {
  width:100%;
  max-width:28.6rem;

  &:hover {
    .product-item-photo {
      img {
        scale: 1.1;
        transition: all .4s ease-in-out;
      }
    }
  }

  .product-item-photo {
    background-color:$colorWhite;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;

    img {
      display:block;
      transition: all .4s ease-in-out;
    }

    .towishlist {
      position: absolute;
      right: 2rem;
      top: 2rem;
      display: block;
      z-index:2;

      .heart {
        display: flex;
        align-items: flex-start;
        height: 1.6rem;
        width: 1.6rem;

        svg {
          fill:none;
          stroke: $colorPrimary;
        }

        &:hover {
          svg {
            fill:$colorSecondary;
            stroke: $colorSecondary;
          }
        }
      }
    }
  }

  .product-item-details {
    margin-top:2rem;

    .product-item-name {
      margin-bottom:1rem;

      .product-item-link {
        color:$colorPrimary;
      }
    }

    .product-item-price {
      .price-details {
          display:none;
      }

      .price-box {
        display:flex;
        flex-wrap:wrap;
        column-gap: 2rem;

        .special-price {
          .price {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 2rem;
            letter-spacing: 4px;
            color:$colorSecondary;
          }
        }

        .old-price {
          .price {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 2rem;
            letter-spacing: 4px;
            color:$colorPrimary;
            text-decoration: line-through;
          }
        }

        .regular-price,
        .price-from,
        .price-to,
        .price-as-configured {
          .price {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 2rem;
            color:$colorPrimary;
            letter-spacing: 4px;
          }

          .price-label {
            display:none !important;
          }
        }

        .from-price {
          .price {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 2rem;
            color:$colorPrimary;
            letter-spacing: 4px;
          }

          .price-label {
            font-size: 1.3rem;
            line-height: 2.4rem;
            letter-spacing: 0.195px;
          }
        }

        .discount {
          display:none;
        }
      }
    }
  }
}
