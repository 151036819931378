body {
  &.catalog-product-view {
    .page-wrapper {
      .page-main {
        padding: 0 2.5rem;

        .columns {
          @include content(medium);
          margin-top:10rem;
          margin-bottom:10rem;

          .column {
            &.main {
              display:flex;
              flex-wrap:wrap;
              justify-content: space-between;

              .product-info {
                &-media {
                  width: 100%;
                  max-width:62rem;
                  max-height:620px;
                  position: relative;

                  .gallery-placeholder {
                    overflow: hidden;

                    &._block-content-loading {
                      .gallery-placeholder__container {
                        padding: 5px;

                        .gallery-placeholder__image {
                          box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
                        }
                      }
                    }
                  }

                  .fotorama-item {
                    .fotorama__wrap {
                      padding: 5px;

                      .fotorama__stage {
                        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);

                        &:before,
                        &:after {
                          display:none;
                        }

                        .fotorama__stage__shaft {
                          .fotorama__stage__frame {
                            .fotorama__img {
                              top: 50% !important;
                            }
                          }
                        }

                        .fotorama__arr {
                          width:5rem;
                        }
                      }

                      .fotorama__nav-wrap {
                        margin-top: 2.7rem;

                        .fotorama__nav {
                          overflow: visible;

                          &:before,
                          &:after {
                            display:none;
                          }

                          .fotorama__thumb__arr {
                            max-height:9.8rem;
                            display: none !important;
                          }

                          &__frame {
                            box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
                            margin: 0 1.3rem 0 0;
                            padding:0;
                            cursor:pointer;

                            &:last-child {
                              margin: 0;
                            }

                            &--dot {
                              height: 3rem;
                              width: 3rem;
                            }

                            .fotorama__dot {
                              height: 8px;
                              width: 8px;
                              left: 11px;
                              top: 11px;
                              background-color: $colorPrimary;
                              border-color: $colorPrimary;
                            }

                            &.fotorama__active {
                              .fotorama__dot {
                                background-color: $colorSecondary;
                                border-color: $colorSecondary;
                              }
                            }
                          }

                          .fotorama__thumb-border {
                            display:none;
                          }
                        }
                      }
                    }
                  }

                  .gallery-additional {
                    display: flex;
                    flex-wrap:wrap;
                    margin-top: 1rem;
                    margin-left: 0.5rem;

                    .product-back {
                      margin-right: 5rem;

                      a {
                        font-weight: 500;
                        font-size: 1.4rem;
                        line-height: 3rem;
                        letter-spacing: 4px;
                        text-transform: uppercase;
                        display: inline-block;
                        padding-bottom: 4px;

                        &.arrow {
                          border-bottom:2px solid;
                          border-color:transparent;

                          &:before {
                            font-family: "Font Awesome 6 Pro";
                            content: "\f078";
                            width: 2rem;
                            display: inline-block;
                          }

                          &:hover {
                            border-color:$colorPrimary;

                            &:before {
                              content: "\f054";
                            }
                          }
                        }
                      }
                    }

                    .product-breadcrumbs {
                      ul {
                        display: flex;
                        flex-wrap:wrap;

                        li {
                          display: flex;

                          &:after {
                            content:">";
                            margin-right: 0.8rem;
                            margin-left: 0.2rem;
                          }

                          &:last-child {
                            &:after {
                              display: none;
                            }
                          }

                          a {
                            font-weight: 500;
                            font-size: 1.4rem;
                            line-height: 3rem;
                            letter-spacing: 4px;
                            text-transform: uppercase;
                          }
                        }
                      }
                    }
                  }
                }

                &-main {
                  width: calc(100% - 62rem - 22rem);

                  .product-info-brand {
                    margin-bottom: 3rem;

                    img {
                      max-width:20rem;
                    }
                  }

                  .page-title-wrapper {
                    margin-bottom:2rem;

                    h1 {
                      font-weight: 400;
                      font-size: 3.4rem;
                      line-height: 4.4rem;
                      color:$colorPrimary;
                    }
                  }

                  .product-info-sku {
                    margin-bottom: 4rem;
                  }

                  .product-info-short-description {
                    margin-bottom: 4rem;

                    .content {
                      position: relative;
                      overflow: hidden;
                      margin-bottom: 2rem;

                      &.closed {
                        max-height: 12rem;

                        /*&:after {
                          content: '';
                          display: inline-block;
                          position: absolute;
                          width: 100%;
                          height: 70px;
                          bottom: 0;
                          pointer-events: none;
                          background: linear-gradient(hsla(0, 0%, 100%, 0), $colorWhite);
                        }*/
                      }

                      ul {
                        margin-top: 2rem;
                        color:$colorPrimary;
                        padding-left: 3rem;
                        list-style: inherit;
                        line-height: 3.65rem;
                      }
                    }

                    > a {
                      font-weight: 500;
                      font-size: 1.4rem;
                      line-height: 3rem;
                      letter-spacing: 4px;
                      text-transform: uppercase;
                      display: inline-block;
                      padding-bottom: 4px;

                      &.arrow {
                        border-bottom:2px solid;
                        border-color:transparent;

                        &:before {
                          font-family: "Font Awesome 6 Pro";
                          content: "\f078";
                          width: 2rem;
                          display: inline-block;
                        }

                        &.active,
                        &:hover {
                          border-color:$colorPrimary;

                          &:before {
                            content: "\f054";
                          }
                        }
                      }
                    }
                  }

                  .product.attribute {
                    &.delivery_time {
                      display: none;
                    }
                  }

                  .bundle-options-container {
                    .box-tocart {
                      margin-bottom:6rem;
                    }

                    + .product-info-price {
                      display: none;
                    }
                  }

                  .product-add-form {
                    margin-bottom:6rem;

                    .product-options-wrapper {
                      > .fieldset {
                        padding: 1rem 0 2rem;

                        fieldset {
                          max-width:45rem;
                          padding: 0;

                          &.fieldset-custom-options {
                            p {
                              &.note {
                                display:none;
                              }
                            }
                          }

                          .field {
                            margin-bottom: 1rem;

                            select {
                              font-size: $fontSizeInput;
                              line-height: $lineHeightDefault;
                              font-weight: 500;
                              letter-spacing: 4px;
                              text-transform: uppercase;
                            }

                            &.choice {
                              display: flex;
                              align-items: center;
                              gap: 2rem;

                              input {
                                margin-bottom:0;

                                &[disabled="disabled"] {
                                  + label {
                                    opacity:.5;
                                    cursor:not-allowed;
                                  }
                                }

                                + label {
                                  line-height: 2rem;
                                  cursor:pointer;

                                  > span {
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: flex-start;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    .box-tocart {
                      .fieldset {
                        max-width:45rem;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .field {
                          select {
                            font-size: $fontSizeInput;
                            line-height: $lineHeightDefault;
                            font-weight: 500;
                            letter-spacing: 4px;
                            text-transform: uppercase;
                            margin: 0;
                          }

                          &.qty {
                            width:100%;
                            margin-bottom:1rem;
                          }

                          &.towishlist {
                            width:4.6rem;
                            margin-right:1rem;
                            margin-bottom:0;

                            a {
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              height: 100%;
                              box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);

                              .heart {
                                display: flex;
                                align-items: flex-start;

                                svg {
                                  fill:none;
                                  stroke: $colorBlack;
                                  height: 2rem;
                                  width: 2rem;
                                }

                                &:hover {
                                  svg {
                                    fill:$colorSecondary;
                                    stroke: $colorSecondary;
                                  }
                                }
                              }
                            }
                          }
                        }

                        .actions {
                          flex:1;

                          button {
                            width:100%;
                          }
                        }
                      }
                    }
                  }

                  .product-info-price,
                  .block-bundle-summary {
                    .price-details {
                      margin-top:3rem;
                      font-size: 1.3rem;
                      line-height: 2.4rem;
                      letter-spacing: 0.195px;
                      display: flex;

                      .separator {
                        margin-right: 0.5rem;
                      }

                      .shipping-details {
                        a {
                          text-decoration: underline;
                        }
                      }
                    }

                    .price-box {
                      display: flex;
                      flex-wrap: wrap;
                      max-width: 45rem;
                      align-items: baseline;

                      .special-price {
                        .price {
                          font-size: 3.4rem;
                          line-height: 4.4rem;
                          color: $colorSecondary;
                        }

                        .price-label {
                          display:none !important;
                        }
                      }

                      .old-price {
                        margin-left: 2rem;

                        .price {
                          font-size: 2rem;
                          line-height: 3rem;
                          letter-spacing: 1.5px;
                          color: $colorPrimary;
                          text-decoration: line-through;
                        }
                      }

                      .regular-price,
                      .price-as-configured {
                        .price {
                          font-size: 3.4rem;
                          line-height: 4.4rem;
                          color: $colorPrimary;
                        }

                        .price-label {
                          display:none !important;
                        }
                      }

                      .from-price {
                        .price {
                          font-size: 3.4rem;
                          line-height: 4.4rem;
                          color: $colorPrimary;
                        }

                        .price-label {
                          font-size: 1.3rem;
                          line-height: 2.4rem;
                          letter-spacing: 0.195px;
                        }
                      }

                      .discount {
                        background-color: $colorSecondary;
                        font-size: 2rem;
                        line-height: 3rem;
                        letter-spacing: 1.5px;
                        color: $colorWhite;
                        padding:5px 10px;
                        margin-left: auto;
                        align-self: center;
                      }
                    }
                  }
                }

                &-detailed {
                  width: 100%;
                  margin-top: 19rem;

                  .items {
                    position: relative;
                    z-index: 1;

                    .data.item {
                      &.title {
                        float: left;
                        margin-right: 6rem;

                        a {
                          font-size: 1.4rem;
                          font-weight: 500;
                          line-height: 3rem;
                          letter-spacing: 4px;
                          text-transform: uppercase;
                        }

                        &.active {
                          border-bottom:2px solid $colorSecondary;
                        }
                      }

                      &.content {
                        display: block;
                        float: right;
                        width: 100%;
                        margin-left: -100%;
                        margin-top: 3rem;
                        padding: 5rem 0;

                        > div {
                          max-width: 931px;
                        }

                        ul {
                          margin-top: 2rem;
                          color:$colorPrimary;
                          padding-left: 3rem;
                          list-style: inherit;
                          line-height: 3.65rem;
                        }

                        table {
                          border: 1px solid $colorBlack !important;
                          width: auto !important;

                          tr {
                            th {
                              text-align:left !important;
                              font-size: 1.4rem !important;
                              font-weight: 500 !important;
                              letter-spacing: 4px !important;
                              line-height: 3rem !important;
                              text-transform: uppercase !important;
                              padding: 0 2rem !important;
                              width: auto !important;
                            }

                            td {
                              text-align:left !important;
                              padding: 0 2rem !important;
                              width: auto !important;
                            }

                            &:first-child {
                              border-bottom: 1px solid $colorBlack !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $largeDesktop) {
  body {
    &.catalog-product-view {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {
                .product-info {
                  &-media {
                    width: calc((100% - 14rem) / 2);
                  }

                  &-main {
                    width: calc((100% - 14rem) / 2);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  body {
    &.catalog-product-view {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {
                .product-info {
                  &-media {
                    width: calc((100% - 8rem) / 2);
                  }

                  &-main {
                    width: calc((100% - 8rem) / 2);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $smallDesktop) {
  body {
    &.catalog-product-view {
      .page-wrapper {
        .page-main {
          .columns {
            margin-top: 4.5rem;
            margin-bottom: 4.5rem;

            .column {
              &.main {
                .product-info {
                  &-media {
                    width: 100%;
                    max-width: 50rem;
                    margin: 0 0 4rem 0;

                    .fotorama-item {
                      .fotorama__wrap {
                        .fotorama__nav-wrap {
                          position: absolute;
                          z-index: 1;
                          bottom: 20px;

                          .fotorama__nav {
                            &__shaft {
                              justify-content: center;
                            }

                            &__frame {
                              box-shadow: none;
                            }
                          }
                        }
                      }
                    }

                    .gallery-additional {
                      display: none;
                    }
                  }

                  &-main {
                    width: 100%;
                  }

                  &-detailed {
                    margin-top: 10rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  body {
    &.catalog-product-view {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {
                .product-info {
                  &-main {
                    .page-title-wrapper {
                      margin-bottom: 2rem;

                      h1 {
                        font-size: 3rem;
                        line-height: 4.2rem;
                      }
                    }

                    .bundle-options-container {
                      .box-tocart {
                        margin-bottom:3rem;
                      }
                    }

                    .product-add-form {
                      margin-bottom: 3rem;
                    }

                    .product-info-short-description {
                      > a {
                        font-size: 1.1rem;
                        line-height: 2.4rem;
                      }
                    }

                    .product-info-price,
                    .block-bundle-summary {
                      .price-details {
                        margin-top:1rem;
                      }

                      .price-box {
                        .special-price {
                          .price {
                            font-size: 2rem;
                            line-height: 3rem;
                          }
                        }

                        .old-price {
                          margin-left: 1rem;

                          .price {
                            font-size: 1.6rem;
                            line-height: 2.4rem;
                          }
                        }

                        .regular-price,
                        .price-as-configured {
                          .price {
                            font-size: 2rem;
                            line-height: 3rem;
                          }
                        }

                        .from-price {
                          .price {
                            font-size: 2rem;
                            line-height: 3rem;
                          }
                        }
                      }
                    }
                  }

                  &-detailed {
                    margin-top: 6rem;

                    .items {
                      .data.item {
                        &.content {
                          .value {
                            max-width: 100%;
                            overflow-x: auto;
                          }

                          table {
                            min-width:60rem !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  body {
    &.catalog-product-view {
      .page-wrapper {
        .page-main {
          .columns {
            margin-top: 0;

            .column {
              &.main {
                display: block;

                .product-info {
                  &-media {
                    width: 100vw;
                    margin: 0 0 4rem -2.5rem;
                    max-width: none;
                    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);

                    .gallery-placeholder {
                      overflow: hidden;

                      &._block-content-loading {
                        .gallery-placeholder__container {
                          padding: 0;

                          .gallery-placeholder__image {
                            box-shadow: none;
                          }
                        }
                      }
                    }

                    .fotorama-item {
                      .fotorama__wrap {
                        padding: 0;

                        .fotorama__stage {
                          box-shadow: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $phone) {
  body {
    &.catalog-product-view {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {
                .product-info {
                  &-main {
                    .product-info-short-description {
                      .content {
                        ul {
                          font-size: 1.1rem;
                          line-height: 2.4rem;
                        }
                      }
                    }
                    .product-add-form,
                    .bundle-options-container {
                      .box-tocart {
                        .fieldset {
                          .field {
                            select {
                              font-size: 1.1rem;
                              line-height: 2.4rem;
                            }
                          }
                        }
                      }
                    }
                  }

                  &-detailed {
                    .items {
                      .data.item {
                        &.title {
                          a {
                            font-size: 1.1rem;
                            line-height: 2.4rem;
                          }
                        }

                        &.content {
                          ul {
                            font-size: 1.1rem;
                            line-height: 2.4rem;
                          }

                          table {
                            tr {
                              th {
                                font-size: 1.1rem !important;
                                line-height: 2.4rem !important;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}