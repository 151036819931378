body {
  .page-header {
    @include content(max);
    position: fixed;
    top:0;
    z-index: 12;
    background-color: $colorWhite;
    transition: all ease 0.2s;
    margin-right:0;

    .header-content {
      .wrapper {
        padding: 2.5rem;
        position: relative;

        .main {
          @include content(medium);
          @include flex($align: center, $wrap: true);
          height: 5.4rem;

          > .section {
            &.left {
              @include flex($justify: flex-start, $align: center);
            }

            &.middle {
              @include flex($justify: center, $align: center);
              position: absolute;
              left: 50%;
              transform: translate(-50%, 0);
            }

            &.right {
              @include flex($justify: flex-end, $align: center);
              //position: fixed;
              position: absolute;
              right: 0;
            }

            .item {
              &.mmenu-header-open {
                button {
                  background: transparent;
                  border:0;
                  cursor:pointer;
                  box-shadow:none;
                  padding:0.7rem 3rem 0.7rem 0;

                  span{
                    font-size: 1.4rem;
                    line-height: 3rem;
                    font-weight:500;
                    letter-spacing: 4px;
                    text-transform:uppercase;
                    color: $colorBlack;
                  }
                }
              }

              &.language-switcher {
                font-size: 1.4rem;
                line-height: 3rem;
                letter-spacing: 4px;
                margin-left:4.5rem;

                .switcher-options {
                  @include flex();

                  .switcher-trigger {
                    margin-right:2rem;
                  }

                  .switcher-dropdown {
                    @include flex();

                    .switcher-option {
                      margin-right:2rem;
                    }
                  }
                }
              }

              .logo {
                display:block;

                img {
                  display:block;
                  max-width: 7.6rem;
                }
              }

              &.links {
                background-color:$colorPrimary;
                transition: all ease 0.2s;
                //position: fixed;
                position: absolute;
                top: -1rem;
                //right: 0;
                max-width:17.8rem;

                &.sticky {
                  top:0;
                  transition: all ease 0.2s;
                  position: fixed;
                }

                &.squad {
                  background-color:$colorSecondary;

                  .squad-wrapper {
                    display:block;
                  }

                  .item-wrapper {
                    padding:0.5rem 2.7rem 0 2.7rem;

                    .towishlist {
                      .heart {
                        &:hover {
                          svg {
                            fill:$colorSecondary;
                            stroke: $colorWhite;
                          }
                        }
                      }
                    }
                  }
                }

                .item-wrapper {
                  @include flex($justify: space-between, $align: center);
                  position:relative;
                  padding:1.2rem 2.7rem;

                  > div {
                    margin-right:3rem;

                    &:last-child {
                      margin-right:0;
                    }

                    a {
                      color:$colorWhite;
                    }

                    i {
                      color:$colorWhite;
                    }
                  }

                  .towishlist {
                    display: block;

                    .heart {
                      display: flex;
                      align-items: flex-start;

                      svg {
                        fill:none;
                        stroke: $colorWhite;
                        height: 2rem;
                        width: 2rem;
                      }

                      &:hover {
                        svg {
                          fill:$colorSecondary;
                          stroke: $colorSecondary;
                        }
                      }
                    }
                  }
                }

                .squad-wrapper {
                  display:none;
                  padding:0 2.7rem 0.5rem 2.7rem;

                  p {
                    font-size: 1.3rem;
                    line-height: 1.4rem;
                    color: $colorWhite;
                    margin: 0;
                  }
                }
              }

              &.search {
                max-width: 17rem;

                &.desktop {
                  display:block;
                  margin-right: 20rem;
                }

                &.mobile {
                  display:none;

                  .block-content {
                    position: absolute;
                    top: 9rem;
                    width: 100%;
                    left: 0;
                    background: $colorGrey;
                    display:none;

                    &.active {
                      display:block;
                    }

                    form {
                      padding: 0.5rem 2.5rem;

                      .field {
                        width:100%;

                        input {
                          max-width:none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.checkout-index-index {
    .page-header {
      .header-content {
        .wrapper {
          .main {
            @include content(medium);
            margin-right:auto;

            > .section {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  body {
    .page-header {
      .header-content {
        .wrapper {
          padding: 2rem 2.5rem;

          .main {
            height: 5rem;

            > .section {
              .item {
                &.logo {
                    img {
                      max-width: 5.6rem;
                    }
                }

                &.search {
                  &.desktop {
                    display:none;
                  }

                  &.mobile {
                    display:block;
                  }
                }

                &.language-switcher {
                  display:none;
                }

                &.mmenu-header-open {
                  button {
                    padding: 0.7rem 1rem 0.7rem 0;

                    &:before {
                      font-size: 2rem;
                    }

                    span {
                      display: none;
                    }
                  }
                }

                &.links {
                  top: -2.2rem;

                  &.squad {
                    .item-wrapper {
                      padding:0.5rem 2rem 0 2rem;
                    }
                  }

                  .item-wrapper {
                    padding: 1rem 2rem;
                  }

                  .squad-wrapper {
                    padding:0 2rem 0.5rem 2rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $smallDesktop) {
  body {
    .page-header {
      .header-content {
        .wrapper {
          .main {
            > .section {
              .item {
                &.links {
                  .item-wrapper {
                    .towishlist {
                      .heart {
                        svg {
                          height: 1.6rem;
                          width: 1.6rem;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  body {
    .page-header {
      .header-content {
        .wrapper {
          padding: 1.5rem 2.5rem;

          .main {
            height: 5rem;

            > .section {
              .item {
                &.logo {
                  img {
                    max-width: 3.8rem;
                  }
                }

                &.links {
                  top: -2.2rem;

                  &.squad {
                    .item-wrapper {
                      padding:0.5rem 1.5rem 0 1.5rem;
                    }
                  }

                  .squad-wrapper {
                    padding:0 1.5rem 0.5rem 1.5rem;

                    p {
                      font-size:0.9rem;
                    }
                  }

                  .item-wrapper {
                    padding: 1rem 1.5rem;

                    > div {
                      margin-right: 1.5rem;
                    }
                  }
                }

                &.search {
                  &.mobile {
                    .block-content {
                      top: 8rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}