body {
  &.account,
  &.customer-account-login,
  &.customer-account-create,
  &.customer-account-forgotpassword,
  &.customer-account-logoutsuccess {
    .page-wrapper {
      .page-main {
        .columns {
          .column {
            &.main {
              .page-title-wrapper {
                margin-bottom: 4rem;
              }

              .login-container {
                display:flex;
                flex-wrap:wrap;
                justify-content: space-between;
                gap:4rem;

                > .block {
                  width:calc((100% - 4rem) / 2);

                  .block-content {
                    padding: 1rem 0 2rem 0;
                  }
                }
              }

              fieldset {
                padding:0;
              }

              .actions-toolbar {
                margin-top:2rem;
                display:flex;
                gap:2rem;
              }

              .block-addresses-list,
              .block-dashboard-info,
              .block-addresses-default,
              .block-dashboard-addresses {
                .block-title {
                  font-weight: 400;
                  font-size: 2.5rem;
                  line-height: 3rem;
                  letter-spacing: 1px;
                  display: block;
                  padding-bottom: 0.5rem;
                  border-bottom: 1px solid #000;
                  margin-bottom: 2rem;

                  strong {
                    font-weight: 400;
                  }

                  a {
                    display: none;
                  }
                }

                .block-content {
                  display:flex;
                  flex-wrap:wrap;
                  justify-content: space-between;
                  gap:3rem;
                  margin-bottom:3rem;

                  .box {
                    width:calc((100% - 3rem) / 2);
                    padding: 2.5rem;
                    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);

                    .box-title {
                      margin-bottom: 1rem;
                      display: block;
                    }

                    .box-actions {
                      margin-top: 1rem;
                    }
                  }

                  .table-wrapper {
                    width:100%;
                    overflow: auto;

                    table {
                      min-width:$tablet;

                      tr {
                        th {
                          font-weight: 500;
                        }
                      }
                    }
                  }
                }
              }

              .form-address-edit {
                fieldset {
                  legend {
                    display:none;
                  }
                }
              }

              .form-edit-account {
                fieldset {
                  legend {
                    display:none;
                  }
                }
              }
            }

            &.side {
              .block-collapsible-nav {
                > .title {
                  display:none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $desktop) {
  body {
    &.account,
    &.customer-account-login,
    &.customer-account-create,
    &.customer-account-forgotpassword,
    &.customer-account-logoutsuccess {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {
                .login-container {
                  > .block {
                    width:100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $smallDesktop) {
  body {
    &.account,
    &.customer-account-login,
    &.customer-account-create,
    &.customer-account-forgotpassword,
    &.customer-account-logoutsuccess {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {
                .page-title-wrapper {
                  margin-bottom: 2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  body {
    &.account,
    &.customer-account-login,
    &.customer-account-create,
    &.customer-account-forgotpassword,
    &.customer-account-logoutsuccess {
      .page-wrapper {
        .page-main {
          .columns {
            .column {
              &.main {
                .block-addresses-list,
                .block-dashboard-info,
                .block-addresses-default,
                .block-dashboard-addresses {
                  .block-content {
                    gap: 2rem;
                    margin-bottom: 2rem;

                    .box {
                      width:100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}